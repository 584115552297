import * as React from "react";
import { graphql, Link, navigate } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Search from "../components/search";

const FundPage = ({ data, pageContext: { numPages, currentPage } }) => {
  const fundList = data.allFundsearchindexJson.edges;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage =
    currentPage - 1 === 1
      ? "/fund/list"
      : `/fund/list/${(currentPage - 1).toString()}`;
  const nextPage = `/fund/list/${(currentPage + 1).toString()}`;
  const handlePageDropdown = (e) => {
    const page = e.target.value;
    const link = page === "1" ? "/fund/list" : `/fund/list/${page}`;
    navigate(link);
  };

  return (
    <Layout>
      <Seo title="รายชื่อกองทุน" />
      <div className="container mx-auto px-4 max-w-screen-sm">
        <section className="mt-4">
          <Search />
        </section>
        <section className="my-8">
          <h1 className="mx-4 my-2 text-gray-700 text-xl">รายชื่อกองทุน</h1>
          <div
            className="bg-white border border-gray-300 text-gray-600 rounded-md m-1"
            key={1}
          >
            <div className="divide-y divide-gray-300">
              {fundList.map((x, index) => {
                return (
                  <div key={index + 1} className="hover:bg-gray-100">
                    <Link to={`/fund/${x.node.abbr}`}>
                      <p className="px-4 py-2">
                        <svg
                          className="inline align-text-top h-5 w-5 text-green-400 mr-1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          />
                        </svg>
                        {x.node.abbr}
                      </p>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
          <p className="text-gray-500 mt-4 mb-2 mx-4 text-center">
            <span>
              หน้า{" "}
              <select
                value={currentPage}
                onChange={handlePageDropdown}
                onBlur={handlePageDropdown}
              >
                {Array.from({ length: numPages }, (_, i) => (
                  <option key={i + 1} value={i + 1}>
                    {i + 1}
                  </option>
                ))}
              </select>{" "}
              / {numPages}
            </span>
          </p>
          <p className="text-gray-500 my-1 mx-4 text-center">
            {!isFirst && (
              <span className="mr-5">
                <Link to={prevPage} rel="prev">
                  ← หน้าก่อนหน้า
                </Link>
              </span>
            )}
            {!isLast && (
              <span>
                <Link to={nextPage} rel="next">
                  หน้าถัดไป →
                </Link>
              </span>
            )}
          </p>
        </section>
      </div>
    </Layout>
  );
};

export default FundPage;

export const query = graphql`
  query listFundsWithData($skip: Int!, $limit: Int!) {
    allFundsearchindexJson(
      filter: { status: { eq: 1 } }
      sort: { fields: abbr }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          abbr
          status
        }
      }
    }
  }
`;
